import React, { useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"

const PortfolioPiece = (props) => {
    const player = useRef(null)
    const { brand, title, videoURL } = props.data.contentfulPortfolioPiece
    const regex = /(?<=\/)\d{5,9}/
    const vimeoID = videoURL.match(regex)
    const vimeoPlayer = `https://player.vimeo.com/video/` + vimeoID

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const Plyr = require('plyr')
            const initialPlyrConfig = {
                "autoplay" : false,
                "clickToPlay" : true,
                "fullscreen" : {
                    "enabled" : true,
                    "fallback" : true,
                    "iosNative" : true,
                    "container" : "#portfolioContainer"
                },
                "keyboard" : {
                    focused: true,
                    global: true
                },
                "muted" : false
            }

            let playerEl = document.querySelector('div#player')
            player.current = new Plyr(playerEl, initialPlyrConfig)
            player.current.on('controlsshown', handleShown)
            player.current.on('controlshidden', handleHide)
            player.current.on('play', handlePlay)
        }
    })

    const handlePlay = (e) => {
        player.current.muted = false
    }

    const handleShown = (e) => {
        let el = document.getElementById("backButton")
        el.classList.remove("hide")
    }

    const handleHide = (e) => {
        let el = document.getElementById("backButton")
        el.classList.add("hide")
    }

    return (
        <div className="individualPortfolioPiece" id="portfolioContainer">
            <div className="plyr__video-embed" id="player">
                <iframe
                    title={title}
                    src={vimeoPlayer}
                    allowFullScreen
                ></iframe>
                <Link to="/work/" id="backButton">X</Link>
            </div>
            <div className="info">
                <p className="brand">{brand}</p>
                <p className="title">{title}</p>
            </div>
        </div>
    )
}

export default PortfolioPiece

export const query = graphql`
    query ($id: String) {
        contentfulPortfolioPiece(id: {eq: $id}) {
            brand
            category
            thumbnail {
              gatsbyImageData
            }
            title
            videoURL
            id
          }
    }
`